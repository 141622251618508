@import '~ethos-design-system/src/components/Media/Media.scss';
@import '~ethos-design-system/src/components/TypeBase/TypeBase.module.scss';
@import '~ethos-design-system/src/components/Colors.scss';

.fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  background-color: white;
}

.container {
  height: var(--Space-72);
  display: flex;
  justify-content: space-between;

  @include for-phone-only {
    height: var(--Space-64);
    padding-left: var(--Space-24);
    padding-right: var(--Space-24);
  }

  @include for-tablet-only {
    padding-left: var(--Space-72);
    padding-right: var(--Space-72);
  }

  @include for-laptop-and-up {
    padding-left: var(--Space-80);
    padding-right: var(--Space-80);
    max-width: 1280px;
    margin: auto;
  }
}

.phoneNumberContainer {
  margin-top: var(--Space-24);
  display: flex;
  flex-direction: column;

  @include for-phone-only {
    margin-top: var(--Space-16);
  }
}

.phoneNumber {
  @extend .Medium500;
  font-size: var(--Space-14);
  line-height: var(--Space-16);
  text-align: right;
  letter-spacing: 0.0045em;
  color: var(--BrandForest) !important;
}

.Eyebrow2 {
  @extend .Theinhardt;
  @extend .Medium500;
  $font-size: 10.5px;
  font-size: $font-size;
  line-height: var(--Space-16);
  letter-spacing: 0.08em;
  text-transform: uppercase;
  color: var(--GraySecondary--translucent);
}

.baseRoot {
  background: var(--GrayLightHover--opaque);
  height: var(--Space-8);
  width: 100%;
  z-index: 1;

  .progressLength {
    height: 100%;
    width: 0;
    background: var(--BrandForest);
  }
}

.partnerLogoMobileContainer {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  margin-top: 4px;

  @include for-tablet-and-up {
    display: none;
  }

  &.pipeLayout {
    margin-top: 0;
  }
}

.partnerLogoContainer {
  display: none;

  @include for-tablet-and-up {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-start;
    margin-top: 12px;

    &.pipeLayout {
      margin-top: 8px;
    }
  }
}

.plus {
  margin: 0 10px;
}

@media (max-width: 320px) {
  .logo {
    max-width: 70px;
  }

  .container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .partnerLogoMobileContainer {
    margin-top: 0;
  }

  .partnerLogoMobile {
    max-height: 14px;
    max-width: 94px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .plus {
    margin: 0 8px;
    display: flex;
    align-items: center;
  }
}

.pipe {
  line-height: 1;
  padding: 0 12px;
}

@media (min-width: 321px) and (max-width: 375px) {
  .logo {
    max-width: 80px;
  }

  .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .plus {
    display: flex;
    align-items: center;
  }

  .partnerLogoMobileContainer {
    margin-top: 0;
  }

  .partnerLogoMobile {
    max-height: 18px;
    max-width: 121px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 100%;
      width: 100%;
    }
  }
}

@include for-phone-only {
  .phoneNumberContainer {
    margin-top: 0;
    justify-content: center;
    align-items: flex-end;

    .Eyebrow2 {
      font-size: 8px;
      line-height: 120%;
      letter-spacing: 0.5px;
    }

    .phoneNumber {
      color: #2a4542 !important;
      font-size: 12px;
      line-height: 14px;
    }
  }
}
