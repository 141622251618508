@import '~ethos-design-system/src/components/Media/Media.scss';

.logo {
  position: relative;
  width: 108px;
  height: 20px;
}

.letter {
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
}
