@import '~ethos-design-system/src/components/Media/Media.scss';
@import '~ethos-design-system/src/components/TypeBase/TypeBase.module.scss';
@import '~ethos-design-system/src/components/Colors.scss';

.container {
  margin: auto;
  height: var(--Space-80);
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;

  @include for-phone-only {
    padding-left: 18px;
    padding-right: 18px;

    svg {
      width: 67px;
    }
  }

  @include for-tablet-only {
    padding-left: 16px;
    padding-right: 16px;
  }

  @include for-laptop-and-up {
    padding-left: var(--Space-80);
    padding-right: var(--Space-80);
    height: 84px;
  }

  svg {
    filter: brightness(0%);
  }
}

.logo {
  height: 64px;
  display: flex;
  align-items: center;
}
