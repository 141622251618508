@import '~ethos-design-system/src/components/Media/Media.scss';
.navbar {
  position: relative;
  height: var(--Space-64);
  z-index: 2;
  width: 100%;
  background-color: var(--White);
}

.placeholder {
  height: var(--Space-64);
  width: 100%;
  position: fixed;
  z-index: 2;
  background-color: var(--White);
}

.loading{
  opacity: 0;
  &.show {
    opacity: 1;
    transition: opacity 300ms linear;
  }
}
