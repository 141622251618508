@import '~ethos-design-system/src/components/Media/Media.scss';

.logo {
  position: relative;
  width: 86.6px;
  height: var(--Space-16);

  @include for-phone-only {
    margin-top: var(--Space-24);
  }

  @include for-tablet-and-up {
    margin-top: var(--Space-32);
  }
}

.letter {
  opacity: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
}
