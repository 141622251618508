@import '~ethos-design-system/src/components/Media/Media.scss';
@import '~ethos-design-system/src/components/TypeBase/TypeBase.module.scss';
@import '~ethos-design-system/src/components/Colors.scss';

.container {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  height: var(--Space-80);
  z-index: 2;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1440px;

  @include for-phone-only {
    padding-left: 18px;
    padding-right: 18px;
  }

  @include for-tablet-only {
    padding-left: var(--Space-72);
    padding-right: var(--Space-72);
  }

  @include for-laptop-and-up {
    padding-left: var(--Space-80);
    padding-right: var(--Space-80);
    height: 84px;
  }

  svg {
    filter: brightness(0%);
  }
}

.logo {
  height: 64px;
  display: flex;
  align-items: center;
}

.phoneNumberContainer {
  display: flex;
  flex-direction: row;
  padding-top: 3px; // baseline fix for alignment
}

.phoneNumber {
  @extend .Medium500;
}

.copy {
  @extend .Regular400;
}

.copy,
.phoneNumber {
  line-height: var(--Space-64);
  letter-spacing: 0em;
  color: #000;
  font-size: 16px;
  @include for-tablet-and-up {
    font-size: 20px;
  }
}
